<script setup>

import { useGlobalStore } from '@/store/global'

const store = useGlobalStore()
const route = useRoute()
const selectedChapter = computed(() => store.getSelectedChapter)
const selectedBook = computed(() => store.getSelectedBook)
const dropDown = computed(() => store.getDropdown)

</script>
<template>
    <button v-outside-click="'chapters'" @click="store.toggleDropdown('chapters')" :class="['font-bold inline-block border-r py-3 px-5', dropDown == 'chapters' ? 'bg-blue-50' : 'hover:bg-blue-50']">
        {{ selectedChapter }}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-3 h-3 inline transition duration-75 ease-out', dropDown == 'chapters' ? 'rotate-180' : '']">
            <path fill="#000" stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    </button>
    <Transition name="slide-top-fade">
    <ul v-if="dropDown == 'chapters'" class="fixed lg:absolute bg-white z-10 left-0 right-0 top-0 bottom-0 lg:bottom-auto lg:top-full lg:w-2/5 lg:max-h-[80vh] overflow-auto mx-auto border shadow-2xl">

        <h2 class="block border-b p-4 text-xs text-center relative">
            <svg @click="store.toggleDropdown('translations')" class="absolute lg:hidden cursor-pointer left-2 text-gray-400 top-0 bottom-0 my-auto w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
            Výber kapitoly
        </h2>

        <div class="grid grid-cols-5 lg:grid-cols-8">
            <li @click="store.toggleDropdown('translations')" class="border-r border-b" v-for="(chapter) in selectedBook.chapters" :key="chapter.id">
                <NuxtLink :class="['block py-3 px-4 text-center', selectedChapter == chapter ? 'bg-blue-50' : 'hover:bg-blue-50']" 
                    :to="getMainHref({translation: store.getSelectedTranslation.identifier, book: store.getSelectedBook.abbreviation, chapter: chapter, routeName: route.name})">
                    {{chapter}}
                </NuxtLink>
            </li>
        </div>

        <div class="px-2 py-4 text-xs text-center">
            Tlačenú Bibliu si môžete kúpiť na <a class="text-blue-500" href="https://www.eshop.biblia.sk/kategoria/biblie/slovenske-biblie" target="_blank" rel="noreferrer">www.eshop.biblia.sk</a>
        </div>
    </ul>
    </Transition>
</template>