<script setup>

import { useGlobalStore } from '@/store/global'
import { useTranslationsStore } from '@/store/translations'
import { useAudioStore } from '@/store/audio'

const store = useGlobalStore()
const translationsStore = useTranslationsStore()
const audio = useAudioStore()
const preferredReadingTranslation = computed(() => translationsStore.translations.find(translation => translation.identifier === store.getSettings.preferredReadingTranslation))
const defaultTranslationsSelection = ref(false);
const bibliaSk = useCookie('biblia_sk', {
    maxAge: 31546000
})

async function handleSetting(type, value){

    if(type == "preferredCompareTranslations"){
        value = store.handlePreferredTranslations(store.getSettings.preferredCompareTranslations, value)
    }

    store.handleSaveSetting(type, value)
    bibliaSk.value[type] = value == undefined ? !bibliaSk.value[type] : value

}

function handleDefaultTranslationsSelection(){
    defaultTranslationsSelection.value = !defaultTranslationsSelection.value
}

</script>

<template>

    <Transition name="fade">
    <div v-if="store.getSidebarType == 'readSettings'" @click="toggleSidebar('readSettings')" :class="['bg-gray-100 z-50 opacity-80 fixed left-0 right-0 top-0 bottom-0 mx-auto']"></div>
    </Transition>

    <Transition name="slide-fade">
    <div v-if="store.getSidebarType == 'readSettings'" class="fixed bg-white top-0 bottom-0 right-0 w-full md:w-96 overflow-auto text-center border-l z-50">

        <h2 class="my-4 uppercase text-xs text-gray-700 relative">
            <svg @click="toggleSidebar('readSettings')" class="absolute lg:hidden cursor-pointer left-2 text-gray-400 top-0 bottom-0 my-auto w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
            Nastavenia
        </h2>

        <h3 class="text-lg font-bold mb-1">Hlavný preklad</h3>

        <div @click="handleDefaultTranslationsSelection()" class="mt-4">
            <div :class="['group block p-2 m-2 rounded cursor-pointer border group hover:bg-blue-50 hover:border-blue-500', defaultTranslationsSelection ? 'border-blue-500 bg-blue-50' : 'border-gray-200 bg-gray-50']">
                <Transition name="fade">
                <div :class="['', defaultTranslationsSelection ? 'hidden' : 'group-hover:hidden' ]">
                    <span class="font-bold">{{ preferredReadingTranslation.identifier.toUpperCase() }}</span>
                    {{ preferredReadingTranslation.name }}
                    
                </div>
                </Transition>
                <Transition name="fade">
                <span :class="['text-blue-500', defaultTranslationsSelection ? '' : 'hidden group-hover:block']">
                    Zmeniť hlavný preklad
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                </span>
                </Transition>
            </div>
        </div>

        <div v-if="defaultTranslationsSelection">

            <div @click="handleDefaultTranslationsSelection(); handleSetting('preferredReadingTranslation', translation.identifier)" :class="['block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group', preferredReadingTranslation.identifier == translation.identifier ? 'bg-green-50 border-green-500' : 'bg-gray-50']" v-for="translation in translationsStore.translations" :key="translation.id">
                <b>{{ translation.identifier.toUpperCase() }}</b>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-6 h-6 inline absolute left-4 text-gray-200 mr-6', preferredReadingTranslation.identifier == translation.identifier ? 'text-green-500' : 'group-hover:text-green-500']">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                {{ translation.name }}
            </div>
        </div>

        <hr class="mt-4">

        <h3 class="text-lg font-bold m-4">Preferované preklady</h3>

        <div class="mt-4">
            <div @click="handleSetting('preferredCompareTranslations', translation.identifier)" :class="['block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group', store.getSettings.preferredCompareTranslations.includes(translation.identifier) ? 'bg-green-50 border-green-500' : 'bg-gray-50']" v-for="translation in translationsStore.translations" :key="translation.id">
                <b>{{ translation.identifier.toUpperCase() }}</b>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-6 h-6 inline absolute left-4 text-gray-200 mr-6', store.getSettings.preferredCompareTranslations.includes(translation.identifier) ? 'text-green-500' : 'group-hover:text-green-500']">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                {{ translation.name }}
            </div>
        </div>

        <hr class="mt-4">

        <h3 class="text-lg font-bold m-4">Nastavenia textu</h3>

        <div @click="handleSetting('showVerses')" :class="['block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group', store.getSettings.showVerses ? 'bg-green-50 border-green-500' : 'bg-gray-50']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-6 h-6 inline absolute left-4 text-gray-200 mr-6', store.getSettings.showVerses ? 'text-green-500' : 'group-hover:text-green-500']">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Zobraziť čísla veršov
        </div>

        <div @click="handleSetting('showVersePerLine')" :class="['block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group', store.getSettings.showVersePerLine ? 'bg-green-50 border-green-500' : 'bg-gray-50']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-6 h-6 inline absolute left-4 text-gray-200 mr-6', store.getSettings.showVersePerLine ? 'text-green-500' : 'group-hover:text-green-500']">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Zobraziť verš na riadok
        </div>

        <div v-if="store.getSelectedTranslation.identifier == 'seb'" @click="handleSetting('showBibleNotes')" :class="['block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group', store.getSettings.showBibleNotes ? 'bg-green-50 border-green-500' : 'bg-gray-50']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-6 h-6 inline absolute left-4 text-gray-200 mr-6', store.getSettings.showBibleNotes ? 'text-green-500' : 'group-hover:text-green-500']">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Zobraziť biblické poznámky
        </div>

        <div v-if="store.getSelectedTranslation.identifier == 'seb'" @click="handleSetting('showBibleReferences')" :class="['block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group', store.getSettings.showBibleReferences ? 'bg-green-50 border-green-500' : 'bg-gray-50']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-6 h-6 inline absolute left-4 text-gray-200 mr-6', store.getSettings.showBibleReferences ? 'text-green-500' : 'group-hover:text-green-500']">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Zobraziť biblické referencie
        </div>

        <!--
        <div class="text-sm block p-2 m-2 rounded border">
            Milí čitateľ, možnosť zobraziť <b>bibliké poznámky</b> a <b>biblické referencie</b> v ekumenickom preklade sme dočasne vypli kvôli technickým problémom, ktoré tým boli spôsobené.
        </div>
        -->

        <div @click="handleSetting('showTitleHeadings')" :class="['block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group', store.getSettings.showTitleHeadings ? 'bg-green-50 border-green-500' : 'bg-gray-50']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-6 h-6 inline absolute left-4 text-gray-200 mr-6', store.getSettings.showTitleHeadings ? 'text-green-500' : 'group-hover:text-green-500']">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Zobraziť nadpisy odsekov
        </div>

        <div @click="handleSetting('showChapterHeadings')" :class="['block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group', store.getSettings.showChapterHeadings ? 'bg-green-50 border-green-500' : 'bg-gray-50']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-6 h-6 inline absolute left-4 text-gray-200 mr-6', store.getSettings.showChapterHeadings ? 'text-green-500' : 'group-hover:text-green-500']">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Zobraziť označenie kapitoly
        </div>
        <div @click="handleSetting('highlightVerse')" :class="['block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group', store.getSettings.highlightVerse ? 'bg-green-50 border-green-500' : 'bg-gray-50']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-6 h-6 inline absolute left-4 text-gray-200 mr-6', store.getSettings.highlightVerse ? 'text-green-500' : 'group-hover:text-green-500']">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Zvýrazňovať verš myšou farebne
        </div>

        <div @click="handleSetting('showAudioPlayer')" :class="['block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group', store.getSettings.showAudioPlayer ? 'bg-green-50 border-green-500' : 'bg-gray-50']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-6 h-6 inline absolute left-4 text-gray-200 mr-6', store.getSettings.showAudioPlayer ? 'text-green-500' : 'group-hover:text-green-500']">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Zobraziť audio prehrávač
        </div>

        <h2 class="mt-4 mb-2 uppercase text-xs text-gray-700">Rýchlosť prehrávania</h2>

        <span class="">{{ store.getSettings.audioRate }} x</span>

        <div class="flex mt-2">
            <div @click="handleSetting('audioRate', rate)" v-for="rate, index in audio.rates" :key="index" :class="['grow relative block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group bg-gray-50', rate == store.getSettings.audioRate ? 'border-green-500' : '']">
                {{ rate }}
            </div>
        </div>

        <h2 class="mt-4 mb-2 uppercase text-xs text-gray-700">Veľkosť písma</h2>

        <span class="">{{ store.getSettings.fontSize }} px</span>

        <div class="flex mt-2">
            <div @click="handleSetting('fontSize', store.getSettings.fontSize - 1)" :class="['grow relative block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group bg-gray-50']">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline absolute left-4 text-gray-500 mr-6 group-hover:text-green-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Zmenšiť
            </div>
            <div @click="handleSetting('fontSize', store.getSettings.fontSize + 1)" :class="['grow relative block p-2 m-2 rounded cursor-pointer border hover:border-green-500 group bg-gray-50']">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline absolute left-4 text-gray-500 mr-6 group-hover:text-green-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Zväčšiť
            </div>
        </div>

        <button @click="toggleSidebar('readSettings')" class="my-6 text-gray-500 hover:text-black">Zatvoriť</button>
        

    </div>
    </Transition>
</template>

<style>

.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>