import { calculateVisibilityForDiv } from "@/utils/calculateVisibilityForDiv"
import { useGlobalStore } from "@/store/global"

export const handleChapter = () => {
    const chapterContainer =
        document.getElementsByClassName("chapter-container")

    if (!chapterContainer.length) return

    const store = useGlobalStore()
    let chapterContainers: any[] = []

    Array.prototype.forEach.call(chapterContainer, function (el) {
        chapterContainers.push([
            el.dataset.chapter,
            calculateVisibilityForDiv(el),
        ])
    })

    let max = Math.max.apply(
        Math,
        chapterContainers.map(function (i) {
            return i[1]
        })
    )

    let selectedChapterToSet = store.getSelectedChapter

    chapterContainers.forEach(function (chapter) {
        if (chapter[1] === max) {
            // @ts-ignore
            selectedChapterToSet = parseInt(chapter[0]) // TODO: Type 'number' is not assignable to type 'boolean'.ts(2322)
        }
    })

    if (store.getSelectedChapter != selectedChapterToSet) {
        store.selectChapter(selectedChapterToSet)

        // @ts-ignore
        const identifier = store.selectedTranslation.identifier // TODO: Property 'identifier' does not exist on type 'boolean'.ts(2339)
        // @ts-ignore
        const abbreviation = store.selectedBook.abbreviation // TODO: Property 'abbreviation' does not exist on type 'boolean'.ts(2339)

        const url = `/citanie/${identifier}/${abbreviation}/${selectedChapterToSet}`

        history.pushState({}, "", url)
    }
}
