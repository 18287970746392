<script setup>

import { useGlobalStore } from '@/store/global'
import { handleBookNavigation } from '@/utils/handleBookNavigation'
import { focusBookSearchInput } from '@/utils/focusBookSearchInput'

const store = useGlobalStore()
const selectedBook = computed(() => store.getSelectedBook)
const selectedTranslation = computed(() => store.getSelectedTranslation)
const dropDown = computed(() => store.getDropdown)
//const showSebDt = computed(() => store.getSettings.showSebDt)
const showSebDt = ref(store.getSettings.showSebDt)

const bookInput = ref('')
const bookInputForFocus = ref('')

const bibliaSk = useCookie('biblia_sk', {
    maxAge: 31546000
})

const filteredBooks = computed(() => {

    const books = store.getSelectedTranslation.books

    const oldTestament = [];
    const deuterocanonical = [];
    const newTestament = [];
    const search = bookInput.value.toLowerCase().replace(/[\u0300-\u036f]/g, "")

    books.forEach(function(book, key) {

        if(book.name.toLowerCase().replace(/[\u0300-\u036f]/g, "").indexOf(search) !== -1){
        
            if(book.testament == "old" && book.category != "deut"){
                oldTestament.push(book)
            }
            if(Boolean(showSebDt.value) && book.category == "deut"){
                deuterocanonical.push(book)
            }

            if(book.testament == "new"){
                newTestament.push(book)
            }

        }

    });

    return {oldTestament, deuterocanonical, newTestament}

})

async function handleDcSetting(type, value){

    store.handleSaveSetting(type, value)
    bibliaSk.value.showSebDt = !value

}

</script>
<template>
    <button v-outside-click="'books'" @click="store.toggleDropdown('books'); focusBookSearchInput(bookInputForFocus)" :class="['font-bold inline-block border-r py-3 px-5', dropDown == 'books' ? 'bg-blue-50' : 'hover:bg-blue-50']">
        <span :class="['hidden md:inline-block', store.getSelectedTranslation.identifier == 'n28' ? 'font-greek' : '', store.getSelectedTranslation.identifier == 'lxx' ? 'font-greek' : '', store.getSelectedTranslation.identifier == 'csl' ? 'font-cyrilic' : '']">{{ selectedBook.name }}</span>
        <span class="inline-block md:hidden">{{ selectedBook.abb_by_translation }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-3 h-3 ml-1 inline transition duration-75 ease-out', dropDown == 'books' ? 'rotate-180' : '']">
            <path fill="#000" stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    </button>
    <Transition name="slide-top-fade">
    <ul v-show="dropDown == 'books'" class="fixed lg:absolute bg-white z-50 left-0 right-0 top-0 bottom-0 lg:bottom-auto lg:top-full lg:w-2/3 2xl:w-2/4 lg:max-h-[80vh] overflow-auto mx-auto border shadow-2xl">
        
        <h2 class="block border-b p-4 text-xs text-center relative">
            <svg @click="store.toggleDropdown('books')" class="absolute lg:hidden cursor-pointer left-2 text-gray-400 top-0 bottom-0 my-auto w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
            Výber knihy
        </h2>

        <!--
            @keyup="handleBookNavigation($event, router, booklistSZ, booklistDT, booklistNZ, selectedInputBook)"
        -->
        <input v-model="bookInput" ref="bookInputForFocus" type="text" placeholder="Začnite písať názov knihy" class="dropdown bg-input-bg text-input-fg border-b w-full py-2 px-4 focus:border-blue-500 focus:outline-none focus:ring-blue-500">

        <div :class="['flex flex-col relative', bookInput ? '' : 'lg:flex-row']">

            <div class="flex-1 shrink bg-white border-b border-r">
                <div class="text-sm font-bold border-b p-2 text-center">Stará zmluva</div>
                <BookLink :books="filteredBooks.oldTestament"/>
                <div v-if="selectedTranslation.identifier == 'seb'">
                    <div class="dropdown text-sm font-bold border-b p-2 text-center">
                        <span>Deuterokánonické knihy</span>
                    </div>
                    <div :class="['flex py-2 px-4', showSebDt ? 'border-b' : '']">
                        <div class="flex text-sm">
                            <div class="w-14 mr-2 text-right">
                                <input id="checkbox" type="checkbox" @click="handleDcSetting('showSebDt', showSebDt)" v-model="showSebDt" class="dropdown cursor-pointer w-4 h-4 text-blue-600 bg-gray-200 rounded">
                            </div>
                            <div class="overflow-hidden">
                                <label for="checkbox" class="dropdown cursor-pointer ml-2 text-sm font-medium">DT knihy sú {{ showSebDt ? 'zapnuté' : 'vypnuté' }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <BookLink :books="filteredBooks.deuterocanonical"/>
            </div>
            <div class="flex-1 shrink bg-white border-b">
                <div class="text-sm font-bold border-b p-2 text-center">Nová zmluva</div>
                <BookLink :books="filteredBooks.newTestament" :type="'nz'" :bookInput="bookInput"/>
            </div>
        </div>
    </ul>
    </Transition>
</template>