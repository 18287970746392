<script setup>

import { useGlobalStore } from '@/store/global'
import { useTranslationsStore } from '@/store/translations'
import { computed } from 'vue'

const store = useGlobalStore()
const translationsStore = useTranslationsStore()

const selectedTranslation = computed(() => store.getSelectedTranslation)
const dropDown = computed(() => store.getDropdown)

</script>

<template>
    <button v-outside-click="'translations'" @click="store.toggleDropdown('translations')" :class="['font-bold inline-block border-l border-r py-3 px-5', dropDown == 'translations' ? 'bg-blue-50' : 'hover:bg-blue-50']">
        <span class="hidden md:inline-block">{{ selectedTranslation.name }}</span>
        <span class="inline-block md:hidden uppercase">{{ selectedTranslation.identifier }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="['w-3 h-3 ml-1 inline transition duration-75 ease-out', dropDown == 'translations' ? 'rotate-180' : '']">
            <path fill="#000" stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    </button>

    <Transition name="slide-top-fade">
    <ul v-if="dropDown == 'translations'" class="fixed lg:absolute bg-white z-10 left-0 right-0 top-0 bottom-0 lg:bottom-auto lg:top-full lg:w-2/3 2xl:w-2/4 lg:max-h-[80vh] overflow-auto mx-auto border shadow-2xl">

        <h2 class="block border-b p-4 text-xs text-center relative">
            <svg @click="store.toggleDropdown('translations')" class="absolute lg:hidden cursor-pointer left-2 text-gray-400 top-0 bottom-0 my-auto w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
            Výber prekladu
        </h2>

        <li @click="store.toggleDropdown('translations')" class="border-b last:border-b-0" v-for="(translation) in translationsStore.translations" :key="translation.id">
            <div v-if="translation.identifier == 'bhs'" class="border-b text-center py-2 text-xs uppercase">Pôvodné jazyky</div>
            <NuxtLink :class="['flex py-3 px-4', selectedTranslation.identifier == translation.identifier ? 'bg-blue-50' : 'hover:bg-blue-50']" 
                :to="'/citanie/' + translation.identifier + '/' + handleNonExistingBookAndChapter(translation)">
                <div class="w-24 shrink-0">
                    <span class="font-bold">{{translation.identifier.toUpperCase()}}</span>
                </div>
                <div>
                    <span class="font-bold block">{{translation.name}}</span>
                    <p class="text-xs text-gray-400" v-if="selectedTranslation.identifier == translation.identifier" v-html="translation.copyright+' '+translation.bibliography"></p>
                </div>
            </NuxtLink>
        </li>

        <div class="px-2 py-4 text-xs text-center">
            Tlačenú Bibliu si môžete kúpiť na <a class="text-blue-500" href="https://www.eshop.biblia.sk/kategoria/biblie/slovenske-biblie" target="_blank" rel="noreferrer">www.eshop.biblia.sk</a>
        </div>
    </ul>
    </Transition>
</template>