import { defineStore } from 'pinia'

interface AudioState {
    status: boolean | string,
    duration: string,
    progressMS: number,
    timer: string,
    progress: string,
    chapter: boolean | number,
    book: boolean | number,
    rateTab: boolean,
    rates: Array<number>
}

export const useAudioStore = defineStore('audio', {
    state: (): AudioState => ({
        status: false,
        duration: "0:00",
        progressMS: 0,
        timer: "0.00",
        progress: "0%",
        chapter: false,
        book: false,
        rateTab: false,
        rates: [1.0, 1.25, 1.5, 1.75, 2.0]
    }),
    actions: {
        handleAudioStatus(value: boolean | string){
            this.status = value
        },
        handleAudioDuration(value: string){
            this.duration = value
        },
        handleAudioProgressMS(value: number){
            this.progressMS = value
        },
        handleAudioTimer(value: string){
            this.timer = value
        },
        handleAudioProgress(value: string){
            this.progress = value
        },
        handleAudioChapter(value: boolean | number){
            this.chapter = value
        },
        handleAudioBook(value: boolean){
            this.book = value
        },
        handleAudioRateTab(value: boolean){
            this.rateTab = value
        }
    }
})