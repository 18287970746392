<script setup>

import { useGlobalStore } from '@/store/global'
import { getMainHref } from '@/utils/getMainHref'

defineProps(['books', 'type', 'bookInput'])
const store = useGlobalStore()
const route = useRoute()
const selectedBook = computed(() => store.getSelectedBook)

</script>
<template>
    <li @click="store.toggleDropdown('books')" class="border-b last:border-b-0" v-for="(book, index) in books" :key="book.id">
        <NuxtLink :class="['flex py-2 px-4', selectedBook.abbreviation == book.abbreviation ? 'bg-blue-50' : 'hover:bg-blue-50', index + 1 == books.length && !bookInput && type == 'nz' ? 'border-b' : '']" 
        :to="getMainHref({translation: store.getSelectedTranslation.identifier, book: book.abbreviation, chapter: store.getSelectedBook.chapters[0],  routeName: route.name})">
            <div class="flex">
                <span class="w-14 text-right mr-4 font-bold">{{ book.abb_by_translation }}</span>
                <span :class="['overflow-hidden', store.getSelectedTranslation.identifier == 'n28' ? 'font-greek' : '', store.getSelectedTranslation.identifier == 'lxx' ? 'font-greek' : '', store.getSelectedTranslation.identifier == 'csl' ? 'font-cyrilic' : '']">{{ book.name }}</span>
            </div>
        </NuxtLink>
    </li>
</template>