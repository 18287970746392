<script lang="ts" setup>

import { useGlobalStore } from '@/store/global'

const store = useGlobalStore()
const route = useRoute()

useHead({ 
    title: 'Biblia.sk | ' + store.getSelectedTranslation.name + " - " + store.getSelectedBook.name + " " + store.getSelectedChapter + ". kapitola",
    meta: [
        { 
            name: 'description',
            content: store.getTexts[store.getSelectedChapter] ? store.getTexts[store.getSelectedChapter][0].search_text : 'Čítajte, porovnávajte a hľadajte v Božom slove naprieč rôznymi prekladmi Biblie. Tento projekt vám zadarmo prináša Slovenská biblická spoločnosť.'
        }
    ],
    bodyAttrs: {
        class: ''
    },
    link: [
        {
            rel: 'canonical',
            href: 'https://biblia.sk/citanie/' + route.params.translation + '/' + route.params.book + '/' + route.params.chapter
        }
    ]
})

definePageMeta({
  name: 'citanie',
  middleware: 'citanie'
})

const showAudioPlayer = computed(() => {
    return Object.keys(store.getTexts).length && store.getSettings.showAudioPlayer && store.getSelectedTranslation.identifier == 'seb'
})

onMounted(() => {
    window.addEventListener('scroll', stickyToolbar);
    window.addEventListener('scroll', handleChapter);
})

onDeactivated(() => {
    window.removeEventListener('scroll', stickyToolbar);
    window.removeEventListener('scroll', handleChapter);
})

</script>
<template>
<div v-footnote-click class="pb-4">
        
    <div id="toolbar" class="mx-auto border-b relative bg-white  z-20">

        <div class="flex justify-center">
            
            <TranslationSelection/>

            <BookSelection/>

            <ChapterSelection/>

            <ReadSettingsButton/>
            
        </div>

    </div>

    <div id="body" class="mx-auto relative container px-4 lg:px-0 max-w-xl min-h-screen">

        <TextContainer/>

        <AudioPlayer v-if="showAudioPlayer"/>

    </div>

    <ReadSettings/>

</div>
</template>