import { useGlobalStore } from "@/store/global"

export const handleActiveVerse = (event: any, textItem: any) => {
    if (
        !event.target.classList.contains("xo") &&
        !event.target.classList.contains("fr") &&
        !event.target.classList.contains("xt-reference") &&
        !event.target.classList.contains("reference-click") &&
        !event.target.classList.contains("ft")
    ) {
        const store = useGlobalStore()
        store.handleActiveVerses(textItem)
    }
}
