<script setup>

import { useGlobalStore } from '@/store/global'
import { useAudioStore } from '@/store/audio'
import { Howl } from 'howler';
import { onBeforeUnmount } from 'vue';

onBeforeUnmount(() => {

  if(sound){
    sound.unload()
  }

  resetPlayer();

});

const store = useGlobalStore()
const audio = useAudioStore()

let sound = false;

const progressBar = ref();

function handlePlayButton(){

  if(audio.status == false){
    createSound();
  }

  if(audio.status == "paused" || audio.status == "loaded"){
      sound.play();
  }
  
  if(audio.status == "playing"){
      sound.pause();
  }

}

async function createSound(){

  audio.handleAudioStatus("loading")

  const { data } = await useFetch("/api/audio", {
    method: "POST",
    body: {
      book: store.getSelectedBook.abbreviation,
      chapter: store.getSelectedChapter
    }
  })

  if(data.value){

    sound = new Howl({
      src: [data.value.src],
      rate: store.getSettings.audioRate,
      html5: true,
      onload: function(){
        audio.handleAudioStatus("loaded")
        audio.handleAudioDuration(formatTime(Math.round(sound.duration())))
        audio.handleAudioChapter(store.getSelectedChapter)
        audio.handleAudioBook( store.getSelectedBook.name)
      },
      onplay: function() {
        audio.handleAudioStatus("playing")
        requestAnimationFrame(step.bind());

      },
      onpause: function() {
        audio.handleAudioStatus("paused")
      },
      onseek: function() {
        requestAnimationFrame(step.bind());
      },
      onfade: function(){
      },
      onend: function(){
        resetPlayer();
      }
    });

    sound.play()

  }else {
    audio.handleAudioStatus(false)
  }

}

function formatTime(secs) {
  var minutes = Math.floor(secs / 60) || 0;
  var seconds = (secs - minutes * 60) || 0;
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

function step() {

  // Determine our current seek position.
  var seek = sound.seek() || 0;
  audio.handleAudioProgressMS(seek.toFixed(3))
  audio.handleAudioTimer(formatTime(Math.round(seek)))
  audio.handleAudioProgress((((seek / sound.duration()) * 100) || 0) + '%')

  if (sound.playing()) {
    requestAnimationFrame(step);
}

}

function seek(per) {
  sound.seek(sound.duration() * per);
}

function handleProgressClick(event){
  seek(event.layerX / progressBar.value.clientWidth);
}

function resetPlayer(){
  audio.handleAudioStatus(false)
  audio.handleAudioDuration("0:00")
  audio.handleAudioTimer("0:00")
  audio.handleAudioProgress("0%")
  audio.handleAudioChapter(false)
  audio.handleAudioBook(false)
  audio.handleAudioRateTab(false)
}

</script>
<template>
    <div :class="['fixed bottom-0 left-0 right-0 max-w-sm text-center mb-4 z-10 px-4', audio.status == 'playing' ? '' : '']">
        <div :class="['bg-white flex items-center rounded-full border relative h-16 shadow-xl transition-all duration-200 hover:border-blue-500', audio.status == 'playing' ? 'w-full border-blue-500' : 'w-16' ]">

            <div class="w-16 h-16 shrink-0">
            </div>

            <Transition name="fade">
              <Loader v-if="audio.status == 'loading'"/>
            </Transition>
            
            <svg @click="handlePlayButton()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" :class="['w-16 -ml-[1px] h-16 inline-block cursor-pointer hover:text-blue-600 absolute left-0 rounded-full transition-all', audio.status == 'playing' ? 'text-blue-500' : 'text-gray-400']">
                <Transition name="fade">
                  <path v-show="audio.status == 'paused' || audio.status == 'loaded' || audio.status == false" fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z" clip-rule="evenodd" />
                </Transition>
                <Transition name="fade">
                  <path v-show="audio.status == 'playing'" fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM9 8.25a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 00.75-.75V9a.75.75 0 00-.75-.75H9zm5.25 0a.75.75 0 00-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 00.75-.75V9a.75.75 0 00-.75-.75h-.75z" clip-rule="evenodd" />
                </Transition>
            </svg>

            <Transition name="fade">
            <div v-if="audio.status == 'playing'" :class="['flex flex-col text-left p-2 transition-all duration-1000']">
              <span class="text-blue-500">
                {{ audio.timer }}
              </span>
              <span class="text-gray-400">
                {{ audio.duration }}
              </span>
            </div>
            </Transition>

            <Transition name="fade">
            <div v-if="audio.status == 'playing'" :class="['flex p-2 text-left flex-col text-sm w-full']">
              <span class="block whitespace-nowrap">{{ audio.book }}</span>
              <span class="block font-bold whitespace-nowrap">{{ audio.chapter }}. kapitola</span>

              <div ref="progressBar" @click="handleProgressClick($event)" class="bg-gray-200 mt-1 rounded overflow-hidden h-2 relative cursor-pointer">
                  <div :style="'width:' + audio.progress" class="bg-green-500 h-4 mb-4">
                  </div>
              </div>

            </div>
            </Transition>

            <Transition name="fade">
            <div v-if="audio.status == 'playing'" class="relative w-16 h-16">
            </div>
            </Transition>

        </div>
    </div>
</template>