<script setup>

import { useGlobalStore } from '@/store/global'

defineProps(['textKey'])

const store = useGlobalStore()
const settings = computed(() => store.getSettings)

</script>
<template>
    <h2 class="text-center font-bold my-6 text-slate-800">
        <span :class="[settings.showChapterHeadings ? 'visible' : 'invisible']">{{ textKey }}. kapitola</span>
    </h2>
</template>