export const stickyToolbar = () => {
    const navbar = document.getElementById("toolbar")

    if (!navbar) return

    const header = document.getElementById("header")
    const mainBody = document.getElementById("body")
    const navbarHeight = navbar.clientHeight

    if (header && mainBody) {
        if (window.pageYOffset >= header.clientHeight) {
            mainBody.style.paddingTop = navbarHeight + "px"
            navbar.classList.add("sticky-position")
        } else {
            mainBody.style.paddingTop = "0"
            navbar.classList.remove("sticky-position")
        }
    }
}
