<script setup>

import { useGlobalStore } from '@/store/global'
import { useAudioStore } from '@/store/audio'
import { handleActiveVerse } from '@/utils/handleActiveVerse'
import { prepareText } from '@/utils/prepareText'

const props = defineProps(['textItem', 'textKey'])
const store = useGlobalStore()
const audio = useAudioStore()
const settings = computed(() => store.getSettings)

function handleParallels(textItem){
    store.fetchParallels(textItem)
}

function verseIsActive(id){
    return store.getActiveVerses.includes(id)
}

function verseIsPlaying(){

    if(
        (
            Number(audio.progressMS) >= Number(props.textItem.timings?.start) && 
            Number(audio.progressMS) <= Number(props.textItem.timings?.end) &&
            audio.chapter == props.textItem.chapter
        ) && 
        audio.status == "playing" &&
        store.getSelectedTranslation.identifier == 'seb'
    ){
        return true
    }else {
        return false
    }

}

const verseContainerClassObject = computed(() => ({
    'verse-container group mr-1 cursor-pointer z-20': true,
    'border-b-2 border-blue-600 border-dotted': verseIsActive(props.textItem.id),
    'block': props.textItem.para && props.textItem.para.type == 'q',
    'block mb-4': props.textItem.para && props.textItem.para.type == 'b',
    'block italic': props.textItem.para && props.textItem.para.type == 'd',
    'block': props.textItem.para && props.textItem.para.type == 'li1'
}))

const verseTextClassObject = computed(() => ({
    'group-hover:bg-sky-100 z-20 text-slate-800': settings.value.highlightVerse,
    'bg-sky-100': verseIsActive(props.textItem.id),
    'font-greek': store.getSelectedTranslation.identifier == 'n28' || store.getSelectedTranslation.identifier == 'lxx',
    'font-cyrilic': store.getSelectedTranslation.identifier == 'csl',
    'font-hebrew': store.getSelectedTranslation.identifier == 'bhs',
    'bg-rose-100': verseIsPlaying()
}))

const textItemStyleObject = computed(() => {

    let fontSize = "";

    if(store.getSelectedTranslation.identifier == 'bhs'){
        fontSize = (settings.value.fontSize + 12) + 'px'
    }else if(store.getSelectedTranslation.identifier == 'n28' || store.getSelectedTranslation.identifier == 'lxx'){
        fontSize = (settings.value.fontSize + 4) + 'px'
    }else {
        fontSize = settings.value.fontSize + 'px'
    }

    return {
        fontSize: fontSize,
        lineHeight: '160%'
    }
})

const verseRtl = reactive({
    'direction': 'rtl',
    'unicode-bidi': 'bidi-override'
})

let scroll = false

const scrollIntoView = (el) => {
    if(scroll == true) return;
    el.scrollIntoView({ behavior: 'smooth', block: 'center'})
    scroll = true;
}

</script>
<template>
    <span :ref="el => ( verseIsPlaying() ? scrollIntoView(el) : false )" @click="handleParallels(textItem), handleActiveVerse($event, textItem)" :class="verseContainerClassObject" :style="store.getSelectedTranslation.identifier == 'bhs' ? verseRtl : ''">
        <span v-if="textItem.para?.type === 'p' || textItem.para?.type === 'q'" class="block"></span>
        <!-- Verse link number -->
        <VerseNumber v-if="settings.showVerses" :textItem="textItem" :verseIsActive="verseIsActive"/> <span 
            :class="verseTextClassObject"
            :style="textItemStyleObject"
            v-html="prepareText(textItem.content)" 
            :data-verse="textItem.verse + textItem.letter"
            :data-letter="textItem.letter"
            :data-chapter="textKey">
        </span>
    </span>
</template>