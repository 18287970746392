<script setup>

import { useGlobalStore } from '@/store/global'

defineProps(['textItem'])

const store = useGlobalStore()
const settings = computed(() => store.getSettings)

</script>
<template>
    <span v-if="textItem.titles && settings.showTitleHeadings">
        <h2 
            v-for="(title, index) in textItem.titles" 
            :key="index" 
            v-html="prepareText(title.title)" 
            :class="'text-heading-' + title.type">
        </h2>
    </span>
</template>