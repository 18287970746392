export const calculateVisibilityForDiv = (el: HTMLElement) => {
    var windowHeight = document.documentElement.clientHeight,
        docScroll = window.scrollY,
        divPosition = el.offsetTop,
        divHeight = el.clientHeight,
        hiddenBefore = docScroll - divPosition,
        hiddenAfter = divPosition + divHeight - (docScroll + windowHeight)

    if (
        docScroll > divPosition + divHeight ||
        divPosition > docScroll + windowHeight
    ) {
        return 0
    } else {
        var result = 100

        if (hiddenBefore > 0) {
            result -= (hiddenBefore * 100) / divHeight
        }

        if (hiddenAfter > 0) {
            result -= (hiddenAfter * 100) / divHeight
        }

        return result
    }
}
