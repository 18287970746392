<script setup>

import InfiniteLoading from "v3-infinite-loading";

import { useGlobalStore } from '@/store/global'

const route = useRoute()
const store = useGlobalStore()
const selectedBook = computed(() => store.getSelectedBook)
const lastLoadedChapter = computed(() => store.getLastLoadedChapter)
const routeVerse = computed(() => route.params.verse ? route.params.verse : false)
const routeEndVerse = computed(() => route.params.endVerse ? route.params.endVerse : route.params.verse)
const textContainerClassObject = computed(() => ({
    'text-right': store.getSelectedTranslation.identifier == 'bhs'
}))
const showInfiniteLoading = computed(() => {

    if(
        !routeVerse.value && 
        route.name == 'citanie' && 
        route.params.translation != 'npk'
    ){
        return true
    }else {
        return false
    }

});

const texts = computed(() => {

    if(routeVerse.value && Object.keys(store.getTexts).length){

        const filteredVerses = store.getTexts[store.getSelectedChapter].filter(text => {
            return text.verse >= routeVerse.value && text.verse <= routeEndVerse.value
        })
        const copyOfTexts = Object.assign({}, store.getTexts)
        copyOfTexts[store.getSelectedChapter] = filteredVerses;
        return copyOfTexts;
        
    }

    return store.getTexts;
})

const loadData = ($state) => {

    const lastChapterOfBook = selectedBook.value.chapters[selectedBook.value.chapters.length - 1]

    if(++lastLoadedChapter.value <= lastChapterOfBook){
        store.fetchTexts(++lastLoadedChapter.value)
    }else {
        $state.complete()
    }
    
}

function scrollToTop() {
    window.scrollTo({
        top: 0
    });
}

</script>
<template>
    <div class="">
    
        <div v-if="!Object.keys(texts).length && texts != false" class="w-14 h-14 relative mx-auto">
            <Loader/>
        </div>

        <div class="block text-center p-8" v-if="texts === false">
            <span class="text-2xl font-bold text-gray-400">Chyba načítania kapitoly...</span>
            <button @click="store.clearTexts(), store.fetchTexts()" class="block bg-blue-500 hover:bg-blue-600 text-white mx-auto p-2 rounded my-4 focus:outline-none focus:ring focus:ring-blue-300">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                Načítať znova
            </button>
        </div>

        <NuxtLink v-if="Object.keys(texts).length && store.getSelectedBook.chapters.includes(parseInt(store.getSelectedChapter) - 1) && !route.params.verse" class="block group relative text-center mx-auto p-2 rounded mt-6 text-gray-300 transition duration-75 hover:text-black" :to="'/citanie/' + store.getSelectedTranslation.identifier + '/' + store.getSelectedBook.abbreviation + '/' + (parseInt(store.getSelectedChapter) - 1)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 absolute left-0 right-0 mx-auto bottom-7 group-hover:text-black transition duration-75">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
            Predchádzajúca kapitola
        </NuxtLink>

        <div v-if="Object.keys(texts).length" class="chapter-container" :class="textContainerClassObject" :data-chapter="key" v-for="(text, key) in texts" :key="text.verse">
            <ChapterContainer 
                :text="text"
                :textKey="key"/>
        </div>

        <NuxtLink :to="'/citanie/' + store.getSelectedTranslation.identifier + '/' + store.getSelectedBook.abbreviation + '/' + store.getSelectedChapter" v-if="routeVerse" class="block text-center bg-blue-500 text-primary-fg mx-auto p-2 rounded mt-8">
            Čítať celú kapitolu
        </NuxtLink>

        <div v-if="Object.keys(texts).length && route.params.translation == 'npk'">
            <NuxtLink @click="scrollToTop" v-if="Object.keys(texts).length && parseInt(store.getSelectedChapter) + 1 <= store.getSelectedBook.chapters.length" class="mb-4 block text-center bg-gray-200 hover:bg-blue-500 hover:text-white border mx-auto p-2 rounded mt-8" :to="'/citanie/' + store.getSelectedTranslation.identifier + '/' + store.getSelectedBook.abbreviation + '/' + (parseInt(store.getSelectedChapter) + 1)">
                {{ parseInt(store.getSelectedChapter) + 1 }}. kapitola
            </NuxtLink>
            <span v-else class="block text-center p-2 text-sm text-gray-700 mt-4">
                Koniec knihy
            </span>
        </div>

        <InfiniteLoading v-if="Object.keys(texts).length > 0 && showInfiniteLoading" @infinite="loadData">
            <template #spinner>
                <div class="w-14 h-14 relative mx-auto">
                    <Loader/>
                </div>
            </template>
            <template #complete>
                <span class="block text-center p-2 text-sm text-gray-700 mt-4">
                    Koniec knihy
                </span>
                <span class="text-center block text-sm py-4">
                    {{ store.getSelectedTranslation.copyright }}
                    {{ store.getSelectedTranslation.bibliography }}
                </span>
            </template>
        </InfiniteLoading>

        <div v-if="!showInfiniteLoading && Object.keys(texts).length" class="block my-4">
            <span class="text-center block text-sm py-4">
                {{ store.getSelectedTranslation.copyright }}
                {{ store.getSelectedTranslation.bibliography }}
            </span>
        </div>

        <TextErrorSidebar/>

    </div>
</template>